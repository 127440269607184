<template>
    <div>
        <st-button
            v-can:create="'application'"
            variant="secondary"
            :callback="addApplication"
        >
            <i class="fa fa-plus"></i>
            {{ $t('APPLICATION.LIST.ADD_BUTTON') }}
        </st-button>
        <st-button
            variant="light"
            class="st-btn-text-primary"
            v-if="canSign"
            :callback="signDocument"
            :disabled="signBtnDisabled || isSignLoading"
            :spinner="isSignLoading"
        >
            <i class="fa fa-save"></i>
            {{ $t("APPLICATION.LIST.SIGN_DOCUMENT_BULK") }}
        </st-button>
        <st-button
            variant="dark"
            customClass="application-refresh-button"
            :callback="refresh"
        >
            <i class="fas fa-sync"></i>
            {{ $t("APPLICATION.LIST.REFRESH_BUTTON") }}
        </st-button>
        <confirm-sign-document-modal
            ref="confirm-sign-document-modal"
            :bulkSigning="true"
            :applicationId="selectedRows"
            @signed="refreshStaffApplications"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import ConfirmSignDocumentModal from "@/modules/applications/components/view-application/ConfirmSignDocumentModal";

    export default {
        name: 'ApplicationsListToolbar',
        props: {
            canSign: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ConfirmSignDocumentModal
        },
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                selectedRows: "applications/list/selectedRows",
                loading: 'shared/loading'
            }),
            signBtnDisabled() {
                return !this.selectedRows.length || this.selectedRows.length == 1;
            },
            isSignLoading() {
                return this.loading["applications/signDocumentBulk"];
            }
        },
        methods: {
            ...mapActions({
                signDocumentBulk: "applications/form/signDocumentBulk",
                doFetch: "applications/list/doFetch",
                setSelectedRows: "applications/list/doSelect"
            }),
            addApplication() {
                this.$router.push({ name: 'applicationNew' });
            },
            signDocument() {
                let applicationIds = this.selectedRows.map((el) => el.id);

                this.signDocumentBulk(applicationIds).then(() => {
                    this.$refs['confirm-sign-document-modal'].show();
                }).catch(() => {
                    Message.error('APPLICATION.VIEW.SIGN_DOCUMENT_ERROR');
                });
            },
            async refreshStaffApplications() {
                await this.doFetch({
                    loadStaff: true,
                    params: {
                        assigned: true
                    }
                });

                this.setSelectedRows([]);
            },
            refresh () {
                this.$emit('refreshApplicationsList');
            }
        },
    };
</script>
<style scoped lang="scss">

</style>
