<template>
    <div>
        <st-data-table
            :items="applications"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :firstColumnSelectable="assigned"
            :headClass="tableHeadClass"
            :serverSideSorting="true"
            @view="onView"
            @edit="onEdit"
            @itemChecked="checkItems"
            responsive="sm"
            hover
            stateModule="applications/list"
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { ApplicationModel } from '@/modules/applications/models/application-model';
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
    import Roles from '@/security/roles';
    const { fields } = ApplicationModel;

    export default {
        name: 'ApplicationsListTable',
        props: {
            assigned: {
                type: Boolean,
                default: false
            },
            headClass: {
                type: String,
                default: 'st-data-table-head'
            }
        },
        data() {
            return {
                presenter: ApplicationModel.presenter,
                actions: [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('COLLECTOR_ACCOUNTS.TOOLTIPS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        hideOnRow: true,
                    },
                ],
                selected: [],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'applications/list/rows',
                currentUser: 'auth/currentUser',
            }),
            hasPermissionToStaffApplications() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            hasPermissionToStaffOwnApplications() {
                return new ApplicationsPermissions(this.currentUser).readOwnStaff;
            },
            hasPermissionToReadStaff() {
                return this.hasPermissionToStaffApplications || this.hasPermissionToStaffOwnApplications;
            },
            isSuperAdmin() {
                return this.currentUser.user_type_name === Roles.values['super_admin']
            },
            fields() {
                let tableFields = [];
                let selectedColumn = {label: '', key: 'selected'};

                if (this.assigned) {
                    tableFields.push(selectedColumn);
                }

                if (this.hasPermissionToStaffOwnApplications || this.isSuperAdmin) {
                    tableFields.push(
                        fields.identification_number.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                        fields.application_date.extendField({ bold: true, sortable: true}),
                        fields.internal_status.extendField({ labelType: true , sortable: true}),
                        fields.response_due_in_days.extendField({ bold: true, sortable: true}),
                        fields.application_type_name.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                        fields.beneficiary.extendField({ customField: true, portalTarget: 'beneficiary-custom-field'}),
                        fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                    );
                    if (this.hasPermissionToStaffApplications) {
                        if (this.assigned) {
                            tableFields = [
                                selectedColumn,
                                fields.assigned_staff_user_name.extendField({ customField: true, portalTarget: 'enable-truncate'})
                            ].concat(tableFields);
                        } else {
                            tableFields = [
                                fields.assigned_staff_user_name.extendField({ customField: true, portalTarget: 'enable-truncate'})
                            ].concat(tableFields);
                        }
                    }
                } else {
                    tableFields.push(
                        fields.identification_number.extendField({ sortable: true}),
                        fields.application_date.extendField({ bold: true, sortable: true}),
                        fields.application_type_name.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                        fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                        fields.response_due_in_days.extendField({ bold: true, sortable: true}),
                        fields.created_date.extendField({ sortable: true}),
                        fields.beneficiary.extendField({ customField: true, portalTarget: 'beneficiary-custom-field'}),
                        fields.is_paid.extendField({ booleanType: true }),
                        fields.is_signed.extendField({ booleanType: true }),
                        fields.status.extendField({ labelType: true , sortable: true})
                    );
                }

                return tableFields;
            },
            applications() {
                return this.rows.map((row) => {
                    let hideAction =  true;
                    if (this.currentUser.user_id && row.assigned_staff_user_id === this.currentUser.user_id) {
                        hideAction = false;
                    }
                    return {
                        ...row,
                        hideAction
                    }
                });

            },
            tableHeadClass() {
                let headerClass = this.headClass;

                if (this.assigned) {
                    headerClass += ' first-column-check';
                }

                return headerClass;
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'applications/form/find',
                doSelect: 'applications/list/doSelect',
            }),
            async onView(data) {
                if (!data?.item?.id) return;
                const application = await this.selectRecord({ id: data.item.id, loadStaff: this.hasPermissionToReadStaff});

                const routeName =  application?.status === 'draft' ? 'applicationEdit' : 'applicationView';
                this.$router.push({
                    name: routeName,
                    params: {
                        id: data.item.id,
                    },
                });
            },
            onEdit(application) {
                this.$router.push({
                    params: {
                        id: application.item.id,
                        activeTab: 'listDocuments'
                    },
                    name: 'applicationView'
                });
            },
            checkItems(data) {
                if (data.item.selected) {
                    this.selected.push(data.item);
                } else {
                    this.selected = this.selected.filter((el) => data.item.id !== el.id);
                }

                this.doSelect(this.selected);
            },
        },
    };
</script>
